.businessSummaryContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.businessSummaryContent {
  padding: 20px;
  border-radius: 15px;
  background: #f1f1f1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40vw;
  flex-shrink: 0;
}

.businessSummaryContent p {
  color: #505050;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.header p {
  font-size: 14px;
  color: #555;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.footer button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  padding: 0;
}

.footer h3 {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.arrowButton {
  background: none;
  border: none;
  color: #007bff;
  font-size: 14px;
  cursor: pointer;
}

.arrowButton:hover {
  text-decoration: underline;
}

.arrowButton p {
  color: #505050;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1024px) {
  .businessSummaryContent {
    width: 60vw;
  }

  .header h2 {
    font-size: 20px;
  }

  .businessSummaryContent p {
    font-size: 14px;
  }

  .footer h3 {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .businessSummaryContent {
    width: 80vw;
    padding: 15px;
  }

  .header h2 {
    font-size: 18px;
  }

  .header p {
    font-size: 12px;
  }

  .businessSummaryContent p {
    font-size: 12px;
  }

  .footer h3 {
    font-size: 12px;
  }

  .arrowButton {
    font-size: 12px;
  }

  .arrowButton p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .businessSummaryContainer {
    margin: 10px;
  }

  .businessSummaryContent {
    width: 90vw;
    padding: 10px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .header h2 {
    font-size: 16px;
  }

  .header p {
    font-size: 10px;
  }

  .businessSummaryContent p {
    font-size: 10px;
  }

  .footer {
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
  }

  .footer h3 {
    font-size: 10px;
  }

  .arrowButton {
    font-size: 10px;
  }

  .arrowButton p {
    font-size: 10px;
  }
}