/* Main Inbox Container */
.inboxContainer {
    display: flex;
    width: 100%;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
  }
  
  /* Sidebar */
  .sidebar {
    width: 30%;
    background-color: #f7f7f7;
    padding: 15px;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
  }
  
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Chat List */
  .chatItem {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .chatItem:hover {
    background-color: #e0e0e0;
  }
  
  .selected {
    background-color: #007bff;
    color: white;
  }
  
  /* Chat Area */
  .chatArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  
  .chatHeader {
    padding: 15px;
    background-color: #007bff;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
  }
  
  .chatMessages {
    flex: 1;
    padding: 15px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  /* Message Bubble */
  .messageBubble {
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
    background-color: #e5e5ea;
    align-self: flex-start;
  }
  
  /* Input Area */
  .inputContainer {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  
  .inputBox {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
  }
  
  .sendButton {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .sendButton:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .inboxContainer {
      flex-direction: column;
      height: auto;
    }
  
    .sidebar {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #ddd;
    }
  }
  