.rentalListingsContainer {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.rentalListingsContainer h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

.rentalList {
  list-style: none;
  padding: 0;
}

.rentalItem {
  background: #fff;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.rentalItem:hover {
  transform: translateY(-3px);
}

.rentalItem strong {
  color: #222;
}

.rentalItem br {
  margin-bottom: 5px;
}
