.manageAdminsWrapper {
  padding: 30px;
  background-color: #f7f8fa;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.header {
  font-size: 26px;
  color: #2c3e50;
  margin-bottom: 20px;
  font-weight: bold;
}

.sectionHeader {
  font-size: 22px;
  color: #2980b9;
  margin-bottom: 15px;
  font-weight: 600;
}

.pendingAdmins {
  margin-bottom: 40px;
}

.pendingList {
  list-style: none;
  padding: 0;
}

.pendingItem {
  background: #fff;
  padding: 18px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.2s ease-in-out;
}

.pendingItem:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.actionButtons button {
  margin-left: 15px;
  padding: 8px 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
}

.approveButton {
  background-color: #27ae60;
  color: #fff;
  font-size: 0.95rem;
}

.rejectButton {
  background-color: #e74c3c;
  color: #fff;
  font-size: 0.95rem;
}

.adminBoxes {
  margin-top: 30px;
}

.boxContainer {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

.adminBox {
  background: #fff;
  padding: 18px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 220px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.adminBox:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.adminBox h3 {
  margin: 0 0 12px;
  color: #2980b9;
  font-size: 1.1rem;
}

.adminBox p {
  margin: 5px 0;
  color: #34495e;
}

.pageTitle {
  font-size: 28px;
  margin-bottom: 25px;
  color: #2c3e50;
}

.sectionTitle {
  font-size: 22px;
  margin: 25px 0 15px;
  font-weight: 600;
}

.adminsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.requestList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.requestItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.requestItem:hover {
  background-color: #f1f1f1;
}

.requestDetails {
  flex: 1;
}

.requestName {
  font-weight: bold;
  color: #2c3e50;
}

.requestEmail {
  display: block;
  font-size: 0.95rem;
  color: #7f8c8d;
}

.requestActions button {
  margin-left: 15px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.95rem;
}

.acceptButton {
  background-color: #27ae60;
  color: #fff;
  margin-left: 15px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.95rem;
}

.acceptButton:hover {
  background-color: #2ecc71;
}

.deleteButton {
  background-color: #e74c3c;
  color: #fff;
}

.deleteButton:hover {
  background-color: #c0392b;
}

.noRequests {
  color: #7f8c8d;
  font-size: 1rem;
}

.listingsSection {
  margin-top: 30px;
}

.listingsList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listingItem {
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.listingItem:last-child {
  border-bottom: none;
}

.approveButton {
  margin-top: 12px;
  padding: 12px 25px;
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.approveButton:hover {
  background-color: #3498db;
}

.deleteButton {
  margin-left: 20px;
  padding: 10px 15px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.deleteButton:hover {
  background-color: #c0392b;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 12px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.table th {
  background-color: #f9f9f9;
  font-weight: bold;
  color: #34495e;
}

.table td button {
  margin-right: 8px;
}

.searchBar {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.searchInput {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.searchInput:focus {
  border-color: #2980b9;
  outline: none;
}

.bulkActions {
  margin-top: 15px;
}
.approvedStatus {
  color: #27ae60;
  font-weight: bold;
}

.pendingStatus {
  color: #e67e22;
  font-weight: bold;
}
.delistButton {
  background-color: #f1c40f;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delistButton:hover {
  background-color: #f39c12;
}

.suspendButton {
  background-color: #8e44ad;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suspendButton:hover {
  background-color: #9b59b6;
}

.blockButton {
  background-color: #e74c3c;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blockButton:hover {
  background-color: #c0392b;
}
