:root {
  --login-form-container-width: 39.27vw;
}

.loginFormMainContainer {
}

.loginFormContainer {
  position: absolute;
  z-index: 99;
  top: calc(50vh - calc(var(--login-form-container-height) / 2));
  left: calc(50vw - calc(var(--login-form-container-width) / 2));
  width: var(--more-options-main-contianer-width);
  height: auto;
  animation: appear 0.5s ease-in-out forwards;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
}

.loginFormTitleSvg {
  display: flex;
  align-items: center;
  padding: 2.77vh 2.08vw;
}

.loginFormTitle {
  flex-grow: 1;
  color: #000;
  font-family: Inter;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin: 0;
}

.loginCloseSvg {
  width: 1.10vw;
  height: 1.96vh;
  cursor: pointer;
  transition: transform 0.3s, fill 0.3s;
}

.loginCloseSvg:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.breakLine {
  width: 100%;
  height: 1px;
  background: #ababab;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginHeading {
  color: #000;
  font-family: Inter;
  font-size: 1.875vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.8px;
  text-align: left;
}

.formMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.field1Container {
  position: relative;
  width: 35.10vw;
  height: 7.31vh;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid #ababab;
}

.fieldLabel {
  color: #505050;
  font-family: Inter;
  font-size: 0.725vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  padding-left: 0.52vw;
}

.field1 {
  width: 100%;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
}

.dropDownSvg {
  position: absolute;
  right: 20px;
  top: 30px;
}

.inputField2 {
  align-items: center;
  width: 34.9vw;
  height: 7.31vh;
  flex-shrink: 0;
  border-radius: 0px 0px 15px 15px;
  border: 1px solid #ababab;
  background: #fff;
  color: #505050;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.loginFormInfo {
  width: 35.10vw;
  color: #000;
  font-family: Inter;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: -0.48px;
  margin: 10px 0;
}

.loginFormLink {
  width: 35.10vw;
  color: #000;
  font-family: Inter;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.48px;
  text-decoration-line: underline;
}

.submitBtnPrimary {
  width: 100%;
  height: 7.31vh;
  flex-shrink: 0;
  border-radius: 15px;
  background: #004acc;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1.875vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 30px 0;
  border: 0;
}

label {
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .loginForm {
    margin: 1rem;
  }
}

.signup {
  background-color: #28a745;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 1rem;
}

.signup:hover,
.signup:focus {
  background-color: #218838;
}
.passwordFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.passwordForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.labelPassword {
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #333;
}

.inputContainer {
  margin-bottom: 15px;
}

.inputUsername,
.inputPassword {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submitBtnSecondary {
  width: 100%;
  padding: 12px;
  background-color: #4caf50;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitBtnSecondary:hover {
  background-color: #45a049;
}
