/* Modal backdrop */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
}

/* Modal box */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Modal buttons */
.modalActions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  flex-wrap: wrap;
}

.cancelButton,
.confirmButton {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  flex: 1;
  min-width: 120px;
  margin: 5px;
}

.cancelButton {
  background-color: #f1f1f1;
}

.confirmButton {
  background-color: #007bff;
  color: white;
}

.cancelButton:hover,
.confirmButton:hover {
  opacity: 0.8;
}

/* Responsive Design */
@media (max-width: 480px) {
  .modal {
    padding: 15px;
  }

  .modalActions {
    flex-direction: column;
  }

  .cancelButton,
  .confirmButton {
    width: 100%;
  }
}
