.yourResidentsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.residentsContentContainer {
  width: 100%;
}

.residentsHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.residentsHeaderContainer h2 {
  margin: 0;
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
}

.residentsAdd {
  display: flex;
  align-items: center;
  gap: 0.3vw;
  border-radius: 20px;
  background: #f1f1f1;
  padding: 8px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.residentsAdd p {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

.residentsHeader {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 0.8fr 0fr;
  gap: 20px;
  padding: 10px;
  font-weight: bold;
}

.residentsInfo {
  display: flex;
  align-items: center;
  gap: 2vw;
}

.residentsInfo h1 {
  color: #000;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.residentsImage {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
}

.threeDotIcon1 {
  display: inline-block;
}

.threeDotMenu {
  position: absolute;
  top: 50px;
  right: 1px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  z-index: 10;
}

.menuItems {
  padding: 8px 12px;
  cursor: pointer;
}

.menuItems:hover {
  background-color: #f5f5f5;
}

.residentsItem {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 0fr;
  gap: 20px;
  padding: 10px;
  align-items: center;
  min-height: 60px;
  transition: background-color 0.2s ease;
}

.residentsItem:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.residentsItem > div {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 0;
}

.residentsItem .floor-info {
  padding-left: 40px;
}

.roomDetails {
  padding-left: 30px;
  margin: 4px 0;
  font-size: 0.9rem;
}

.residentsItem p:first-child {
  font-weight: 500;
  margin: 8px 0;
}

.residentsHeader > span,
.residentsItem > div {
  display: flex;
  align-items: center;
  height: 100%;
}

.status {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 15px;
  font-size: 0.9rem;
}

.actionButtons {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.floorItem {
  padding: 10px 15px;
  cursor: pointer;
  background-color: #f8f8f8;
  border-left: 3px solid #007bff;
  transition: background-color 0.2s ease;
}

.floorItem:hover {
  background-color: #e0e0e0;
}

.floorItem p {
  margin: 0;
  font-weight: bold;
}

.arrowIcon {
  width: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 5px;
}

.floorInfo {
  padding-left: 10px;
  font-weight: 500;
  cursor: pointer;
}

.rowWhite {
  background-color: white;
}

.rowGray {
  background-color: #f5f5f5;
}

.residentNameColumn {
  display: flex;
  align-items: center;
}

.floorText {
  font-size: 1rem;
}

.roomRow {
  padding-left: 25px;
  font-size: 0.9rem;
  color: #666;
}

.floorRow {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  font-weight: 500;
}

.floorRow:hover {
  background-color: #e0e0e0;
}

.roomRow {
  padding-left: 25px;
  font-size: 0.9rem;
  color: #666;
}
.loadMoreButton {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.loadMoreButton:hover {
  background-color: #0056b3;
}

.loadMoreButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
