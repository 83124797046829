.addResident {
  margin-bottom: 20px;
}

.addResident input {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #1a237e;
  border-radius: 4px;
  background-color: white;
  color: #1a237e;
}

.addResident input:focus {
  outline: 2px solid #42a5f5;
}

.addResident button {
  padding: 8px 16px;
  background-color: #1a237e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.addResident button:hover {
  background-color: #3949ab;
}

.residentList,
.requestList {
  list-style-type: none;
  padding: 0;
}

.residentList li,
.requestList li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #1a237e;
  border-radius: 4px;
  background-color: #bbdefb;
}

.residentList li span,
.requestList li span {
  color: #1a237e;
}

.residentManagementButtons button {
  margin-left: 8px;
  padding: 4px 8px;
  background-color: #1e88e5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.residentManagementButtons button:hover {
  background-color: #1976d2;
}

/* button:last-of-type {
  background-color: #d32f2f; 
} */

/* button:last-of-type:hover {
  background-color: #b71c1c;
} */

/* Section headings */
.card .h2,
.h3 {
  color: #1a237e;
}

.residentStats {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: space-evenly;
}
