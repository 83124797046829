.secureBookingContainer {
  display: flex;
  justify-content: space-between;
  padding: 5vh 6vw;
}

.secureBookingLeftContainer {
  display: flex;
  gap: 1vw;
}

.secureBookingBackBtn {
  cursor: pointer;
  width: 2vw;
  height: 4.2vh;
  background: transparent;
  flex-shrink: 0;
  border-radius: 25px;
  border: none;
}

.secureBookingBackBtn:hover {
  background-color: #dbdbdb;
}

.secureBookingHeader {
  display: flex;
  align-items: center;
}

.secureBookingHeader h1 {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.rentalDetailsContainer {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  gap: 2vh;
}

.rentalDetailsContainer h3 {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rentalDetailsContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rentalDetails h4 {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.rentalDetails p {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.rentalDetailsContent a {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.secureBookingNoteContainer label {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.secureBookingNoteContainer p {
  color: #505050;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.secureBookingTiming {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.secureBookingTiming p {
  width: 466px;
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.secureBookingButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.secureBookingCancelBtn {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  padding: 0;
}

.secureBookingBookBtn {
  width: 193px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background: #004acc;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.secureBookingRightContainer {
  width: 464px;
  height: 303px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #ababab;
  padding: 5px 10px;
}

.rentalCardHeader {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.rentalCardImg {
  width: 109px;
  height: 92px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.rentalCardContentRating h3 {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rentalCardContentRating span:first-child {
  color: gold;
  margin-right: 4px;
}

.rentalCardContentRating span {
  margin-right: 6px;
  color: #666;
}

.totalRentalSections {
  margin-bottom: 0.5vh;
}

.totalRentalSections p {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.totalBreakDownSections {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #333;
}

.totalBreakDownSections p {
  color: #000;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.totalAmountSections {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  color: #333;
}
