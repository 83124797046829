.facilitiesDialog {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.facilitiesDialogContainer {
  background: #fff;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  border-radius: 30px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
  animation: appear 0.5s ease-in-out forwards;
}

.facilitiesDialogHeaderContainer {
  border-bottom: 1px solid #ababab;
  padding: 2.77vh 2.08vw;
}

.dialogHeaderContent {
  display: flex;
  justify-content: center;
  position: relative;
}

.facilitiesDialogCloseIcon {
  position: absolute;
  top: 0.6vh;
  left: 0.2vw;
  width: 20px; /* Fixed size for better responsiveness */
  height: 20px; /* Fixed size for better responsiveness */
  cursor: pointer;
  transition: transform 0.3s, fill 0.3s;
}

.dialogHeaderTitle {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: clamp(16px, 2.5vw, 24px); /* Responsive font size */
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.facilitiesDialogContent {
  padding: 20px 2.18vw;
  max-height: 60vh; /* Adjusted for better responsiveness */
  overflow: auto;
}

.facilitiesDialogContent h1 {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: clamp(18px, 2vw, 22px); /* Responsive font size */
  font-weight: 400;
  margin: 0 0 10px 0;
}

.facilitiesDialogContent p {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: clamp(12px, 1.2vw, 16px); /* Responsive font size */
  font-weight: 300;
  margin: 8px 0 0 0;
}

.facilitiesDialogFooterContainer {
  border-top: 1px solid #ababab;
  padding: 1.8vh 1.8vw;
}

.facilitiesDialogFooterContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 10px; /* Add gap between buttons */
}

.facilitiesDialogFooterContent button {
  background: transparent;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  font-size: clamp(14px, 1.2vw, 16px); /* Responsive font size */
  padding: 8px 16px;
  border-radius: 10px;
  border: none;
}

.facilitiesDialogFooterContent button:hover {
  background: #f3f3f3;
}

.facilitiesDialogFooterLinkBtn {
  cursor: pointer;
  background: rgb(42, 42, 42);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.facilitiesDialogFooterLinkBtn:hover {
  background: #000;
}

.facilitiesDialogFooterLinkBtn a {
  color: #fff;
  font-weight: 600;
  font-size: clamp(14px, 1.2vw, 16px); /* Responsive font size */
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .facilitiesDialog{
    padding: 20px;
  }

  .facilitiesDialogContainer {
    width: 95%; /* Increase width for smaller screens */
    max-width: 95%; /* Adjust max-width */
  }

  .facilitiesDialogHeaderContainer {
    padding: 2vh 2vw; /* Adjust padding */
  }

  .facilitiesDialogCloseIcon {
    width: 10px; /* Smaller icon for smaller screens */
    height: 10px;
  }

  .dialogHeaderTitle {
    font-size: clamp(14px, 4vw, 18px); /* Adjust font size */
  }

  .facilitiesDialogContent {
    padding: 15px 2vw; /* Adjust padding */
  }

  .facilitiesDialogContent h1 {
    font-size: clamp(16px, 3vw, 20px); /* Adjust font size */
  }

  .facilitiesDialogContent p {
    font-size: clamp(12px, 2vw, 14px); /* Adjust font size */
  }

  .facilitiesDialogFooterContainer {
    padding: 1.5vh 1.5vw; /* Adjust padding */
  }

  .facilitiesDialogFooterContent button {
    font-size: clamp(12px, 2vw, 14px); /* Adjust font size */
    padding: 6px 12px; /* Adjust padding */
  }

  .facilitiesDialogFooterLinkBtn a {
    font-size: clamp(12px, 2vw, 14px); /* Adjust font size */
    padding: 10px 16px; /* Adjust padding */
  }
}

@media (max-width: 480px) {
  .facilitiesDialog{
    padding: 20px;
  }

  .facilitiesDialogContainer {
    width: 100%; /* Full width for very small screens */
    max-width: 100%;
    border-radius: 20px; /* Smaller border radius */
  }

  .facilitiesDialogHeaderContainer {
    padding: 1.5vh 1.5vw; /* Further adjust padding */
  }

  .dialogHeaderTitle {
    font-size: clamp(12px, 5vw, 16px); /* Further adjust font size */
  }

  .facilitiesDialogContent {
    padding: 10px 1.5vw; /* Further adjust padding */
  }

  .facilitiesDialogContent h1 {
    font-size: clamp(14px, 4vw, 18px); /* Further adjust font size */
  }

  .facilitiesDialogContent p {
    font-size: clamp(10px, 2.5vw, 12px); /* Further adjust font size */
  }

  .facilitiesDialogFooterContainer {
    padding: 1vh 1vw; /* Further adjust padding */
  }

  .facilitiesDialogFooterContent button {
    font-size: clamp(10px, 2.5vw, 12px); /* Further adjust font size */
    padding: 5px 10px; /* Further adjust padding */
  }

  .facilitiesDialogFooterLinkBtn a {
    font-size: clamp(10px, 2.5vw, 12px); /* Further adjust font size */
    padding: 8px 12px; /* Further adjust padding */
  }
}