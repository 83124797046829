.amenitiesContainer {
  margin: 20px 0;
}

.amenitiesContainer h1 {
  font-size: clamp(1.5rem, 4vw, 2rem); /* Responsive font size */
  font-weight: 600;
  margin-top: 0;
 }

.amenitiesContainer h2 {
  font-size: clamp(1rem, 3vw, 1.2rem); /* Responsive font size */
  font-weight: 600;
  margin: 10px 0; /* Add margin for better spacing */
}

.amenitiesCheckboxContainer {
  display: flex;
  
  margin: 20px 0;
  gap: 20px;
}

.checkboxColumn {
  display: flex;
  flex-direction: column;
  width: 100%; /* Full width on smaller screens */
  max-width: 30vw; /* Limit width on larger screens */
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px; /* Add spacing between checkbox and label */
}

.optionLink {
  font-size: clamp(0.9rem, 2.5vw, 1rem); /* Responsive font size */
  text-decoration: underline;
  color: black;
  margin-top: 10px; /* Add margin for better spacing */
  text-align: center; /* Center align for better responsiveness */
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .amenitiesContainer h1 {
    font-size: clamp(1.2rem, 5vw, 1.5rem); /* Adjust font size for smaller screens */
  }

  .amenitiesContainer h2 {
    font-size: clamp(0.9rem, 3.5vw, 1rem); /* Adjust font size */
  }

  .amenitiesCheckboxContainer{
    flex-wrap: wrap;
    gap: 0;
  }

  .checkboxColumn {
    max-width: 45vw; /* Adjust width for smaller screens */
  }

  .optionLink {
    font-size: clamp(0.8rem, 2.2vw, 0.9rem); /* Adjust font size */
  }
}

@media (max-width: 480px) {
  .amenitiesContainer h1 {
    font-size: clamp(1rem, 6vw, 1.2rem); /* Further adjust font size */
  }

  .amenitiesContainer h2 {
    font-size: clamp(0.8rem, 3vw, 0.9rem); /* Further adjust font size */
  }

  .amenitiesCheckboxContainer{
    flex-wrap: wrap;
    gap: 0;
  }

  .checkboxColumn {
    max-width: 100%; /* Full width on very small screens */
  }

  .optionLink {
    font-size: clamp(0.7rem, 2vw, 0.8rem); /* Further adjust font size */
  }
}