.residentsStatsContainer {
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.residentsStatsContainer h2 {
  display: flex;
  border-radius: 0px 5px 0 0px;
  background: #f1f1f1;
  width: fit-content;
  margin: 0;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 1vh 1vw;
}

.residentsStatsContentContainer {
  border-radius: 0px 5px 5px 5px;
  background: #f1f1f1;
}

.residentsStatsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7vh 3vw;
}

.statsInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;

  flex-shrink: 0;
}

.statItem {
  display: flex;
  gap: 8px;
}

.statItem p{
    margin: 0;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.black-dot {
  background-color: black;
}

.grayDot {
  background-color: gray;
}

.statValue {
  font-weight: bold;
}

.statsChart {
  display: flex;
  justify-content: center;
}

.donutChart {
  width: 100px;
  height: 100px;
}

.otherStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 1vw;
}

.otherStatus a {
  text-decoration: none;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
