

.clearButton{
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  border: none;
}

:global(.MuiDateRangeCalendar-root) {
  border: none !important;
}
