.collapsible {
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.chevron {
  transition: transform 0.3s ease;
}

.chevron.open {
  transform: rotate(90deg);
}

.collapsible-content {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease, padding 0.3s ease;
  position: relative;
  padding-left: 20px; /* Space for the vertical line */
}

.collapsible-content.open {
  max-height: 500px;
  padding-top: 10px;
}

/* Vertical Line with Dot Using ::before */
.collapsible-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 8px;
  width: 2px;
  height: 100%;
  background: gray;
  transition: height 0.3s ease;
  opacity: 0;
}

.collapsible-content.open::before {
  opacity: 1;
}

/* Dot at the Top of the Line */
.collapsible-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 4px;
  width: 8px;
  height: 8px;
  background: gray;
  border-radius: 50%;
  opacity: 0;
}

.collapsible-content.open::after {
  opacity: 1;
}

.content-text {
  /* flex: 1; */
}