.reviewsContainer {
  width: 100%;
  max-width: 1000px;
  font-family: Arial, sans-serif;
}

.reviewsContainer h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.averageRating {
  color: gray;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.reviewsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px;
}

.reviewsCard {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reviewsHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.memberInfo {
  color: gray;
  font-size: 0.85rem;
}

.reviewsRating {
  font-size: 0.9rem;
  color: #f39c12;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeAgo {
  color: gray;
  font-size: 0.85rem;
}

.reviewsContent {
  font-size: 0.95rem;
  color: #333;
  margin-bottom: 10px;
}

.showMoreReviews {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
}

.showMoreReviews:hover {
  text-decoration: underline;
}

.showAllReviews {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  width: 246px;
  height: 55px;
  text-align: center;
  margin: 40px 0;
  cursor: pointer;
  background-color: transparent;
}

.showAllReviews:hover {
  background-color: #f0f0f0;
}

.showAllReviews h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reviewsDialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.reviewsDialogContent {
  width: 90%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
}

.closeBtn {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.closeBtn:hover {
  color: #ff0000;
}
