.priceSliderContainer {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #000;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
}

.histogram {
  flex: 1;
  display: flex;
  align-items: flex-end;
  height: 100px;
  margin: 0 10px;
  position: relative;
  width: 100%;
  max-width: 600px;
}

.bar {
  width: 8px;
  margin: 0 1px;
  background-color: #000;
}

.priceValues {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
}

.priceBox {
  text-align: start;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 45%;
}

.priceBox label {
  display: block;
  font-size: 0.8em;
  color: #777;
  margin-bottom: 5px;
}

.priceBox input {
  border: none;
  font-size: 1.2em;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .histogram {
    height: 80px;
  }
  .bar {
    width: 6px;
  }
  .priceValues {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .priceBox {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .histogram {
    height: 60px;
  }
  .bar {
    width: 5px;
  }
  .priceValues {
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  .priceBox {
    width: 100%;
  }
}
