.pendingRequests {
  width: auto;
}

.pendingContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  overflow: hidden;
  border-radius: 0px 5px 5px 5px;
  background: #f1f1f1;
}

.pendingRequests h2 {
  display: flex;
  border-radius: 0px 5px 0 0px;
  background: #f1f1f1;
  width: fit-content;
  margin: 0;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 1vh 1vw;
}

.requestCardContainer {
  width: 100%;
  overflow: hidden;
}

.arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 2;
  user-select: none;
}

.arrow:first-of-type {
  left: 0;
}

.arrow:last-of-type {
  right: 0;
}

.carouselWindow {
  flex: 1;
  overflow: hidden;
}

.carouselTrack {
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
}

.carouselItem {
  flex: 0 0 auto;
  box-sizing: border-box;
  padding: 16px; /* Adjust for spacing */
}

.requestCardContent {
  display: flex;
  gap: 0.5vw;
  align-items: center;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  width: 15vw;
}

.profileInfo {
  text-align: center;
}

.profileInfo h1 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.listingInfoImgContainer {
  display: flex;
  align-items: center;
  gap: 0.5vw;
}

.listingInfo h1 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-align: left;
}

.listingInfo p {
  color: #000;
  font-family: Inter;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.listingImg {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
}

.reserveBtn {
  width: 125px;
  height: 39px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #000;
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.declineBtn {
  width: 119px;
  height: 39px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #000;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
