.adminDashboardMain {
  min-height: 40vh;
  background: #fff;
  padding: 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2vh;
}

.textPrimary {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 48px;
  font-weight: 600;
  margin: 0;
}

.textSecondary {
  color: #505050;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-weight: 400;
}

.textNormal {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.textDangerContainer {
  gap: 4.73vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.textDanger {
  color: #ac0000;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.2px;
}

.textLinkNormal {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  text-decoration: underline;
  cursor: pointer;
}

.adminDashboardButton {
  width: 255px;
  height: 62px;
  border-radius: 15px;
  background: #fff;
  cursor: pointer;
  border: 1px solid rgba(171, 171, 171, 0.5);
  transition: border 0.3s ease-in-out;
}

.adminDashboardButton:hover {
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.adminDashboardButtonText {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-weight: 400;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .adminDashboardMain {
    padding: 60px 60px;
  }

  .textPrimary {
    font-size: 36px;
  }

  .textSecondary,
  .textDanger,
  .textLinkNormal,
  .textNormal {
    font-size: 18px;
  }

  .adminDashboardButton {
    width: 200px;
    height: 50px;
  }

  .adminDashboardButtonText {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .adminDashboardMain {
    padding: 40px 40px;
    flex-direction: column;
    align-items: center;
  }

  .textPrimary {
    font-size: 28px;
  }

  .textSecondary {
    font-size: 16px;
  }

  .textNormal,
  .textDanger,
  .textLinkNormal {
    font-size: 18px;
  }

  .adminDashboardButton {
    width: 180px;
    height: 45px;
  }

  .adminDashboardButtonText {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .adminDashboardMain {
    padding: 20px 20px;
  }

  .textPrimary {
    font-size: 24px;
    text-align: center;
  }

  .textSecondary {
    font-size: 14px;
    text-align: center;
  }

  .textNormal {
    font-size: 14px;
  }

  .textDanger {
    font-size: 14px;
  }

  .textLinkNormal {
    font-size: 14px;
  }

  .adminDashboardButton {
    width: 150px;
    height: 40px;
  }

  .adminDashboardButtonText {
    font-size: 13px;
  }
}
