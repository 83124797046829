.viewPropertyMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewPropertyContentContainer {
  width: 100%;
  padding: 0 5vw;
}

.viewPropertyMainContainer a {
  color: black;
  text-decoration: underline;
}

.propertyHeaderContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewPropertyIconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.viewPropertyHeaderIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}

.viewPropertyHeaderIcons:hover {
  background-color: rgb(240, 240, 240);
  border-radius: 8px;
}

/* General container for images */
.viewPropertyImagesContainer {
  display: flex;
  gap: 10px;
  /* width: 100%; */
  position: relative;
  height: 60vh;
}

/* Left Div for the first image */
.leftDiv {
  width: 50%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden; /* Ensures images do not overflow the borders */
  border: 2px solid transparent; /* Optional: Add a border to the container */
}

/* Right Div for the grid layout */
.rightDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 50%;
  height: auto; /* Ensure height adjusts dynamically */
  gap: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  border: 2px solid transparent;
}

/* Styles for main image in left div */
.mainImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subImageContainer {
  width: 100%;
  overflow: hidden; /* Ensures images do not overflow the container */
}

.subImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Button to show all photos */
.viewPropertyImagesBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 20px;
  border: 1px solid black;
  border-radius: 10px;
  background: #fff;
  padding: 0px 10px;
  touch-action: manipulation;
  transition: background-color 0.3s ease;
  width: auto;
  height: 48px;
  margin: 0;
  cursor: pointer;
  gap: 0.2vw;
}

.viewPropertyImagesBtn h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.viewPropertyImagesBtn:hover {
  background-color: #f0f0f0;
}

.mainContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.viewPropertyBuildingDetailsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ratingsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 0.5px solid rgba(171, 171, 171, 0.5);
  background: #fff;
  width: 634px;
  height: 100px;
  flex-shrink: 0;
  padding: 0 30px;
  text-align: center;
  cursor: pointer;
}

.ratingsContainer h3 {
  color: black;
}

.hostContainer h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hostDetailsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hostDetails h1 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.hostDetails p {
  color: #505050;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.viewPropertyAboutContainer {
  width: 827px;
  height: 167px;
  flex-shrink: 0;
}

.viewPropertyAboutContainer h1 {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.viewPropertyAboutContainer h4 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.viewPropertyAboutContainer a {
  display: flex;
  width: 9vw;
  align-items: center;
  /* gap: 3px; */
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.amenitiesPropertyContainer {
  width: 684px;
  /* height: 327.997px; */
  flex-shrink: 0;
}

.amenitiesColumns {
  display: flex;
  gap: 15vw;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.amenitiesItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}

.amenitiesItem h2 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.showAllAmenities {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  width: 246px;
  height: 55px;
  text-align: center;
  margin: 40px 0;
  cursor: pointer;
}

.showAllAmenities:hover {
  background-color: #f0f0f0;
}

.showAllAmenities h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reservationContainer {
  float: right;
  margin-left: 20px;
  position: sticky;
  top: 12vh;
}
