.featuresCardsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.featuresCardsContent {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 55vw;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
    cursor: pointer;
  }
  
  .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .iconContainer{
    margin-bottom: 1vh;
  }

  .cardContent h3 {
    font-size: 18px;
  }
  
  .cardContent p {
    font-size: 14px;
    color: #7a7a7a;
  }
  
  .lockOverlay {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #7a7a7a;
  }
  
  .lockedCard {
    opacity: 0.6;
    pointer-events: none;
  }
  