.reservationBoxContainer {
  width: 472px;
  height: 357px;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid rgba(171, 171, 171, 0.5);
  background: #fff;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
  font-family: Arial, sans-serif;
}

.reservationBoxPrice {
  display: flex;
  gap: 5px;
  align-items: center;
}

.reservationBoxPrice p {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.reservationBoxPrice h1 {
  color: #000;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.reservationBoxSubtitle {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.inputSelectContainer {
  margin: 16px 0;
}

.inputGroupCheckIn {
  width: auto;
  height: 55px;
  flex-shrink: 0;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid #000;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px;
  cursor: pointer;
}

.checkInTitle {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.checkInDescription {
  color: #505050;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.selectGroupCheckIn {
  width: auto;
  height: 55px;
  flex-shrink: 0;
  border-radius: 0px 0px 15px 15px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  background: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
}

.reservationDropdown {
  position: absolute;
  top: 24vh;
  left: 1vw;
  width: 22.8vw;
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 1vw 2vh;
}

.reservationDropdownHeader h1 {
  color: #000;
  font-family: Inter;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

.reservationDropdownHeader p {
  color: #000;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.reservationDropdownItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reservationDropdownItem h2 {
  color: #000;
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.reservationDropdownItem p {
  color: #000;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 5px;
}

.counter {
  display: flex;
  gap: 10px;
  align-items: center;
}

.counter button {
  border-radius: 15px;
  border: 1px solid #e9e9e9;
  background: #fff;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  cursor: pointer;
}

.counter h1 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reservationActionsBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reservationDrpdwnCloseBtn {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  padding: 0;
}

.reservationDrpdwnSaveBtn {
  width: 70px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background: black;
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reserveButton {
  width: 100%;
  padding: 20px 0;
  font-size: 1.5rem;
  font-weight: normal;
  color: white;
  border-radius: 15px;
  border: none;
  background: #004acc;
  cursor: pointer;
  margin-bottom: 15px;
  font-weight: bold;
}

.reserveButton:hover {
  background: #0056b3;
}

.reservationButtonActions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.callButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 16vw;
  height: 55px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid rgba(171, 171, 171, 0.5);
  background: #fff;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
}

.whatsappButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8vw;
  height: 55px;
  flex-shrink: 0;
  border-radius: 15px;
  background-color: #000;
  border: none;
  cursor: pointer;
}

.callButton:hover {
  background: #f8f9fa;
}

.whatsappButton:hover {
  background: #058e00;
}

.icon {
  margin-right: 5px;
}

.calendarDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.calendarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

.calendarContent {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  z-index: 1002;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  width: 40%;
}

.closeButton {
  border: none;
  cursor: pointer;
  background: transparent;
}

.saveButton {
  margin-top: 10px;
  background: #0066ff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.saveButton:hover {
  background: #004fcc;
}

.callDialogueContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.callDialogueContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 22vw;
  position: relative;
}

.callDialogueHeaderContent {
  display: flex;
}

.hostInfo h2 {
  text-align: center;
  margin: 0;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hostInfo p {
  text-align: center;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin: 20px;
}

.contactRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.contactIconDetail {
  display: flex;
  align-items: center;
  gap: 1.5vw;
}

.contactInfo h3 {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
}

.contactInfo a {
  color: #005dff;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.copyButton {
  color: black;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  gap: 5px;
  align-items: center;
}
