.placeTypeMainContainer {
  margin: 20px 0;
}

.placeTypeMainContainer h1 {
  font-size: clamp(1.5rem, 4vw, 2rem); /* Responsive font size */
  font-weight: 600;
  text-align: center; /* Center align for better responsiveness */
}

.placeTypeButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.buttonsTypeContainer {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
  justify-content: center; /* Center buttons for better responsiveness */
  border-radius: 20px;
  gap: 10px; /* Add gap between buttons */
}

.buttonType {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  color: #000000;
  padding: clamp(10px, 3vw, 20px) clamp(20px, 10vw, 70px); /* Responsive padding */
  font-size: clamp(14px, 2vw, 16px); /* Responsive font size */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  flex: 1 1 auto; /* Allow buttons to grow and shrink */
  text-align: center; /* Center text for better responsiveness */
}

.buttonType:not(:last-child) {
  border-right: 1px solid #cccccc; /* Add border for all buttons */
}

.buttonType.selected {
  background-color: #000000;
  color: #ffffff;
}

.buttonType.selected h1 {
  font-size: clamp(0.9rem, 2.5vw, 1rem); /* Responsive font size */
  font-weight: 600;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .placeTypeMainContainer h1 {
    font-size: clamp(1.2rem, 5vw, 1.5rem); /* Adjust font size for smaller screens */
  }

  .buttonType {
    padding: clamp(8px, 2.5vw, 15px) clamp(15px, 8vw, 50px); /* Adjust padding */
    font-size: clamp(12px, 1.8vw, 14px); /* Adjust font size */
  }

  .buttonType.selected h1 {
    font-size: clamp(0.8rem, 2.2vw, 0.9rem); /* Adjust font size */
  }
}

@media (max-width: 480px) {
  .placeTypeMainContainer h1 {
    font-size: clamp(1rem, 6vw, 1.2rem); /* Further adjust font size */
  }

  .buttonType {
    padding: clamp(6px, 2vw, 10px) clamp(10px, 6vw, 40px); /* Further adjust padding */
    font-size: clamp(10px, 1.5vw, 12px); /* Further adjust font size */
  }

  .buttonType.selected h1 {
    font-size: clamp(0.7rem, 2vw, 0.8rem); /* Further adjust font size */
  }
}