.adminDashWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.adminDashContainer {
  display: flex;
  gap: 45px;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.dashButton {
  color: #6a6a6a;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  height: 45px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.dashButton:hover {
  text-decoration: underline;
  color: black;
}

.menuContainer {
  position: relative;
}

.dropdownMenu {
  position: absolute;
  margin: 20px;
  width: 200px;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.dropdownMenu .dashButton:hover {
  background-color: #b4b4b4;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .adminDashContainer {
    gap: 30px;
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .adminDashContainer {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .dashButton {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .adminDashWrapper {
    padding: 10px;
  }

  .dashButton {
    font-size: 18px;
  }

  .dropdownMenu {
    width: 150px;
    margin: 10px;
  }
}
