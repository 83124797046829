.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialogContent {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 40vw;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.dialogHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.allAmenitiesContainer{

}

.allAmenitiesContainer h1 {
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.allAmenitiesContainer h3 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.closeButton {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.dialogBody {
  margin-bottom: 20px;
}

.dialogFooter {
  text-align: right;
}
