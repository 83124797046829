.needHelpContainer {
    /* position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; */
    padding: 10vh;
  }
  
  .helpButton {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .helpButton:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .helpButton:active {
    transform: scale(1);
    background-color: #004080;
  }
  
  .helpContent {
    margin-top: 1rem;
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 300px;
  }
  
  .helpContent p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    color: #333;
  }
  
  .helpContent strong {
    color: #007bff;
  }