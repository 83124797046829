/* General Container Styling */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  padding: 2rem;
  width: 100%;
}

/* Flexbox Layout for Buttons */
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem; /* Ensures equal spacing between buttons */
  width: 100%;
  max-width: 450px;
}

/* Card Styling (Used for Buttons) */
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  min-width: 250px;
  padding: 1.5rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  flex-direction: column;
}

/* Hover and Click Effects */
.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Card Content */
.card h1 {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.5rem;
}

/* Icon Styling */
.icon {
  font-size: 1.8rem;
  color: gray;
  transition: color 0.3s ease;
}

.card:hover .icon {
  color: black;
}

/* Search Container */
.searchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: all 0.3s ease-in-out;
}

/* Search Input */
.searchWrapper {
  width: 100%;
}

.searchInput {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.75rem;
  border-radius: 8px;
  background-color: #f5f5f5;
}

/* Search Results */
.searchResults {
  list-style: none;
  padding: 0;
  margin-top: 8px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: white;
}

.searchResultItem {
  padding: 0.75rem;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.searchResultItem:hover {
  background-color: #f0f0f0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .button-container {
    width: 90%;
  }

  .card {
    width: 100%;
    padding: 1rem;
  }

  .card h1 {
    font-size: 1rem;
  }

  .icon {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .card {
    padding: 0.8rem;
  }

  .card h1 {
    font-size: 0.9rem;
  }

  .icon {
    font-size: 1.3rem;
  }
}

.loader {
  border: 3px solid white;
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 0.6s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Disable button styling */
.submitButton:disabled {
  background-color: #b0c4de;
  cursor: not-allowed;
}
