Main Wrapper .manageAdminsWrapper {
  padding: 20px;
  font-family: "Inter", sans-serif;
}

.listingsContainer {
  width: 100%;
}

.pageTitle {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #000;
}

.sectionTitle {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #333;
}

.pendingManagersWrapper {
  margin-bottom: 30px;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.pendingManagerCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.pendingManagerCard:hover {
  background: #f1f1f1;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.managerInfo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.managerAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #eee;
}

.managerDetails {
  display: flex;
  flex-direction: column;
}

.managerName {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.managerEmail {
  font-size: 14px;
  color: #666;
}

.actionButtons {
  display: flex;
  gap: 10px;
}

.actionButton {
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.acceptButton {
  background-color: #28a745;
  color: #fff;
}

.acceptButton:hover {
  background-color: #218838;
}

.deleteButton {
  background-color: #dc3545;
  color: #fff;
}

.deleteButton:hover {
  background-color: #c82333;
}

/* Admins Table */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background: #f4f4f4;
  font-weight: 600;
}

.table tr:hover {
  background: #f9f9f9;
}

.noAdmins {
  color: #888;
  font-size: 16px;
  text-align: center;
  margin: 20px 0;
}

/* Bulk Actions */
.bulkActions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.bulkActions button {
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.approveButton {
  background-color: #28a745;
  color: #fff;
}

.approveButton:hover {
  background-color: #218838;
}

.suspendButton {
  background-color: #ffc107;
  color: #000;
}

.suspendButton:hover {
  background-color: #e0a800;
}

.delistButton {
  background-color: #17a2b8;
  color: #fff;
}

.delistButton:hover {
  background-color: #138496;
}

.blacklistButton,
.deleteButton {
  background-color: #dc3545;
  color: #fff;
}

.blacklistButton:hover,
.deleteButton:hover {
  background-color: #c82333;
}

/* Search Input */
.searchInput {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
}

.listingsBackIcon {
  margin-bottom: 15px;
  cursor: pointer;
}

.listingsContent {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Header */
.listingsHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listingsHeaderContainer h2 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-top: 0;
}

.searchContainer {
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 100vw;
  background-color: #f1f1f1;
  border-radius: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  transition: all 0.1s ease-in-out;
}

.searchInput {
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.75rem;
  border-radius: 24px;
  margin: 0;
  background-color: #f1f1f1;
}

.searchResults {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.searchResultItem {
  padding: 0.75rem;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.searchResultItem:hover {
  background-color: #f0f0f0;
}

.closeIcon {
  font-size: 1.2rem;
  color: gray;
  cursor: pointer;
  transition: color 0.1s ease;
}

.closeIcon:hover {
  color: black;
}

/* Responsive */
@media (max-width: 600px) {
  .card {
    width: 90%;
    padding: 1rem;
  }

  .searchContainer {
    width: 95%;
  }
}

.listingsAdd {
  display: flex;
  align-items: center;
  gap: 0.3vw;
  border-radius: 20px;
  background: #f1f1f1;
  padding: 8px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.listingsAdd p {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.listingsDetailsBtns {
  display: flex;
  gap: 10px;
  margin: 15px 0;
}

.filterButton {
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.filterButton:hover {
  background-color: #e6e6e6;
  border-color: #ccc;
}

.activeButton {
  background-color: #007bff;
  color: #fff;
  border-color: #0056b3;
}

/* Listings Table Header */
.listingsHeader {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 0.9fr 1fr;
  font-weight: 600;
  color: #555;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.listingsHeader span {
  text-align: left;
}

/* Listings Item */
.listingsItem {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 0.9fr;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease;
}

.listingsItem:hover {
  background-color: #f9f9f9;
}

.listingsInfo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.listingImage {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  object-fit: cover;
  background-color: #ddd;
}

.listingsItem h1 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.listingsItem p {
  font-size: 14px;
  color: #666;
}

.listingsItem span {
  font-size: 14px;
  color: #555;
}

/* Status Styling */
.statusPublished {
  color: #28a745;
}

.statusPending {
  color: #ffc107;
}

.statusUnpublished {
  color: #dc3545;
}

.statusDraft {
  color: #6c757d;
}

/* Three-Dot Menu */
.threeDotIcon {
  font-size: 16px;
  cursor: pointer;
}

.threeDotMenu {
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  z-index: 10;
}

.menuItems {
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  color: #333;
  transition: background-color 0.3s ease;
}

.menuItems:hover {
  background-color: #f1f1f1;
}

/* Utility Styles */
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.hidden {
  display: none;
  font-family: "Courier New", Courier, monospace;
}

.loadMoreButton {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff; /* Blue color */
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.loadMoreButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.loadMoreButton:active {
  transform: scale(0.95); /* Slight shrink on click */
}

.loadMoreButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
