.propertyTypeMainContainer {
  padding: 20px 0;
}

.propertyTypeMainContainer h1 {
  font-size: 2em;
  font-weight: 600;
  margin-top: 0;
}

.propertyOptionsContainer {
  cursor: pointer;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.propertyIconContentContainer {
  padding: 20px;
  border: 2px solid #dedede;
  border-radius: 15px;
  width: 9vw;
  min-width: 120px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.propertyIconContentContainer:hover {
  border: 2px solid #000;
  background-color: rgb(240, 240, 240);
}

.propertyIconContentContainer h2 {
  font-size: 1.1em;
  margin-bottom: 0;
  margin-top: 25px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .propertyTypeMainContainer h1 {
    font-size: 1.5em;
  }
  .propertyOptionsContainer {
    gap: 15px;
  }
  .propertyIconContentContainer {
    width: 30vw;
    padding: 15px;
  }
}

@media screen and (max-width: 480px) {
  .propertyTypeMainContainer h1 {
    font-size: 1.2em;
  }
  .propertyOptionsContainer {
    flex-direction: column;
    gap: 10px;
  }
  .propertyIconContentContainer {
    width: 30vw;
    padding: 10px;
  }
}
